import React, {useEffect} from 'react'
import {useTable, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy} from 'react-table'
import {matchSorter} from "match-sorter";
// A great library for fuzzy filtering/sorting items

// Define a default UI for filtering
function GlobalFilter({
                          preGlobalFilteredRows, globalFilter, setGlobalFilter,
                      }) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (<span>
      Search:{' '}
        <input
            value={value || ""}
            onChange={e => {
                setValue(e.target.value);
                onChange(e.target.value);
            }}
            placeholder={`${count} records...`}
            style={{
                fontSize: '1.1rem', border: '0',
            }}
        />
    </span>)
}

// Define a default UI for filtering
function DefaultColumnFilter({
                                 column: {filterValue, preFilteredRows, setFilter},
                             }) {
    const count = preFilteredRows.length

    return (<input
        value={filterValue || ''}
        onChange={e => {
            setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
        placeholder={`Search ${count} records...`}
    />)
}

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({
                                       column: {filterValue, setFilter, preFilteredRows, id},
                                   }) {
    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set()
        preFilteredRows.forEach(row => {
            options.add(row.values[id])
        })
        return [...options.values()].sort()
    }, [id, preFilteredRows])

    // Render a multi-select box
    return (<select
        value={filterValue}
        onChange={e => {
            setFilter(e.target.value || undefined)
        }}
    >
        <option value="">All</option>
        {options.map((option, i) => (<option key={i} value={option}>
            {option}
        </option>))}
    </select>)
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
function SliderColumnFilter({
                                column: {filterValue, setFilter, preFilteredRows, id},
                            }) {
    // Calculate the min and max
    // using the preFilteredRows

    const [min, max] = React.useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        preFilteredRows.forEach(row => {
            min = Math.min(row.values[id], min)
            max = Math.max(row.values[id], max)
        })
        return [min, max]
    }, [id, preFilteredRows])

    return (<>
        <input
            type="range"
            min={min}
            max={max}
            value={filterValue || min}
            onChange={e => {
                setFilter(parseInt(e.target.value, 10))
            }}
        />
        <button onClick={() => setFilter(undefined)}>Off</button>
    </>)
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
function NumberRangeColumnFilter({
                                     column: {filterValue = [], preFilteredRows, setFilter, id},
                                 }) {
    const [min, max] = React.useMemo(() => {
        let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0
        preFilteredRows.forEach(row => {
            min = Math.min(row.values[id], min)
            max = Math.max(row.values[id], max)
        })
        return [min, max]
    }, [id, preFilteredRows])

    return (<div
        style={{
            display: 'flex',
        }}
    >
        <input
            value={filterValue[0] || ''}
            type="number"
            onChange={e => {
                const val = e.target.value
                setFilter((old = []) => [val ? parseInt(val, 10) : undefined, old[1]])
            }}
            placeholder={`Min (${min})`}
            style={{
                width: '70px', marginRight: '0.5rem',
            }}
        />
        to
        <input
            value={filterValue[1] || ''}
            type="number"
            onChange={e => {
                const val = e.target.value
                setFilter((old = []) => [old[0], val ? parseInt(val, 10) : undefined])
            }}
            placeholder={`Max (${max})`}
            style={{
                width: '70px', marginLeft: '0.5rem',
            }}
        />
    </div>)
}

function fuzzyTextFilterFn(rows, id, filterValue) {
    return matchSorter(rows, filterValue, {keys: [row => row.values[id]]})
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val

// Our table component
export function ASTable({columns, data, rowEvents, initialState, className}) {
    const filterTypes = React.useMemo(() => ({
        // Add a new fuzzyTextFilterFn filter type.
        fuzzyText: fuzzyTextFilterFn, // Or, override the default text filter to use
        // "startWith"
        text: (rows, id, filterValue) => {
            return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefinedThis ? String(rowValue)
                    .toLowerCase()
                    .startsWith(String(filterValue).toLowerCase()) : true
            })
        },
    }), [])

    const defaultColumn = React.useMemo(() => ({
        // Let's set up our default Filter UI
        Filter: DefaultColumnFilter,
    }), [])
    const defaultPropGetter = () => ({})
    const {
        getTableProps,
        getTableBodyProps,
        getColumnProps = defaultPropGetter,
        getRowProps = defaultPropGetter,
        getHeaderProps = defaultPropGetter,
        headerGroups,
        rows,
        prepareRow,
        state,
        visibleColumns,
        setHiddenColumns,
        preGlobalFilteredRows,
        setGlobalFilter,
    } = useTable({
            columns, data, initialState: initialState, defaultColumn, // Be sure to pass the defaultColumn option
            filterTypes,
        }, useFilters, // useFilters!
        useGlobalFilter, // useGlobalFilter!
        useSortBy)

    useEffect(() => {
        setHiddenColumns(columns.filter(column => column.hidden).map(column => column.id));
    }, [columns]);
    // We don't want to render all of the rows for this example, so cap
    // it for this use case

    return (<>
        <table {...getTableProps()} className={className}>
            <thead>
            {headerGroups.map(headerGroup => (<tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (<th {...column.getHeaderProps(column)}>
                    <div {...column.getHeaderProps([{
                        className: column.className, style: column.style,
                    }, getColumnProps(column), getHeaderProps(column), column.getSortByToggleProps()])}>
                        {/* Render the columns filter UI */}
                        <div>{column.canFilter ? column.render('Filter') : <br/>}</div>
                        {column.render('Header')}
                        {/* Add a sort direction indicator */}
                        <span>
                                        {column.isSorted ? column.isSortedDesc ? ' 🔽' : ' 🔼' : ''}
                                      </span>
                    </div>
                </th>))}
            </tr>))}
            <tr>
                <th
                    colSpan={visibleColumns.length}
                    style={{
                        textAlign: 'left',
                    }}
                >
                </th>
            </tr>
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
                prepareRow(row)
                return (<tr {...row.getRowProps()}
                            onClick={() => (rowEvents && rowEvents.onClick) ? rowEvents.onClick(row.original) : null}>
                    {row.cells.map(cell => {
                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    })}
                </tr>)
            })}
            </tbody>
        </table>
        {/*    <br/>*/}
        {/*    <div>*/}
        {/*<pre>*/}
        {/*  <code>{JSON.stringify(state.filters, null, 2)}</code>*/}
        {/*</pre>*/}
        {/*    </div>*/}
    </>)
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
    return rows.filter(row => {
        const rowValue = row.values[id]
        return rowValue >= filterValue
    })
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = val => typeof val !== 'number'

