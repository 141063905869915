export const GET_NAVIGATION_TASK_SUCCESSFUL = "GET_NAVIGATION_TASK_SUCCESSFUL";
export const GET_NAVIGATION_TASK_FAILED = "GET_NAVIGATION_TASK_FAILED";
export const GET_CONTESTANT_DATA_SUCCESSFUL = "GET_CONTESTANT_DATA_SUCCESSFUL";
export const GET_CONTESTANT_DATA_PLAYBACK_SUCCESSFUL = "GET_CONTESTANT_DATA_PLAYBACK_SUCCESSFUL";
export const SET_DISPLAY = "SET_DISPLAY";

export const CURRENT_TIME = "CURRENT_TIME";
export const WEB_SOCKET_STATUS = "WEB_SOCKET_STATUS";
export const NEW_CONTESTANT = "NEW_CONTESTANT";
export const DELETE_CONTESTANT = "DELETE_CONTESTANT";

export const SHRINK_TRACKING_TABLE = "SHRINK_TRACKING_TABLE";
export const EXPAND_TRACKING_TABLE = "EXPAND_TRACKING_TABLE";

export const DISPLAY_ALL_TRACKS = "DISPLAY_ALL_TRACKS";
export const EXPLICITLY_DISPLAY_ALL_TRACKS = "EXPLICITLY_DISPLAY_ALL_TRACKS";
export const EXCLUSIVE_DISPLAY_TRACK_FOR_CONTESTANT = "EXCLUSIVE_DISPLAY_TRACK_FOR_CONTESTANT";

export const HIDE_LOWER_THIRDS = "HIDE_LOWER_THIRDS";
export const SHOW_LOWER_THIRDS = "SHOW_LOWER_THIRDS";

export const HIGHLIGHT_CONTESTANT_TRACK = "HIGHLIGHT_CONTESTANTS_TRACK";
export const REMOVE_HIGHLIGHT_CONTESTANT_TRACK = "REMOVE_HIGHLIGHT_CONTESTANT_TRACK";
export const HIGHLIGHT_CONTESTANT_TABLE = "HIGHLIGHT_CONTESTANT_TABLE";
export const REMOVE_HIGHLIGHT_CONTESTANT_TABLE = "REMOVE_HIGHLIGHT_CONTESTANT_TABLE";

export const TOGGLE_OPEN_AIP = "TOGGLE_OPEN_AIP";
export const TOGGLE_SECRET_GATES = "TOGGLE_SECRET_GATES";
export const TOGGLE_BACKGROUND_MAP = "TOGGLE_BACKGROUND_MAP";
export const TOGGLE_PROFILE_PICTURES = "TOGGLE_PROFILE_PICTURES";

export const FETCH_INITIAL_TRACKS = "FETCH_INITIAL_TRACKS";
export const FETCH_INITIAL_TRACKS_SUCCESS = "FETCH_INITIAL_TRACKS_SUCCESS";
export const FETCH_INITIAL_TRACKS_FAILED = "FETCH_INITIAL_TRACKS_FAILED";

export const FETCH_SCORE_DATA = "FETCH_SCORE_DATA";
export const FETCH_SCORE_DATA_SUCCESS = "FETCH_SCORE_DATA_SUCCESS";
export const FETCH_SCORE_DATA_FAILED = "FETCH_SCORE_DATA_FAILED";

export const TOGGLE_GATE_ARROW = "TOGGLE_GATE_ARROW"
export const TOGGLE_DANGER_LEVEL = "TOGGLE_DANGER_LEVEL"

// Global map
export const GET_CONTESTS = "GET_CONTESTS"
export const GET_CONTESTS_SUCCESSFUL = "GET_CONTESTS_SUCCESSFUL";
export const GET_ONGOING_NAVIGATION_SUCCESSFUL = "GET_ONGOING_NAVIGATION_SUCCESSFUL";

export const GLOBAL_MAP_ZOOM_FOCUS_CONTEST = "GLOBAL_MAP_ZOOM_FOCUS_CONTEST";
export const DISPLAY_EVENT_SEARCH_MODAL = "DISPLAY_EVENT_SEARCH_MODAL";

export const DISPLAY_DISCLAIMER_MODAL = "DISPLAY_DISCLAIMER_MODAL";
export const DISPLAY_ABOUT_MODAL = "DISPLAY_ABOUT_MODAL";
export const FETCH_DISCLAIMER_SUCCESSFUL = "FETCH_DISCLAIMER_SUCCESSFUL";

// Contest participation
export const FETCH_MY_PARTICIPATING_CONTESTS = "FETCH_MY_PARTICIPATING_CONTESTS"
export const FETCH_MY_PARTICIPATING_CONTESTS_SUCCESSFUL = "FETCH_MY_PARTICIPATING_CONTESTS_SUCCESSFUL";

export const FETCH_EDITABLE_ROUTE_SUCCESSFUL = "FETCH_EDITABLE_ROUTE_SUCCESSFUL"
export const FETCH_EDITABLE_ROUTE = "FETCH_EDITABLE_ROUTE"

export const GLOBAL_MAP_SET_VISIBLE_CONTESTS = "GLOBAL_MAP_SET_VISIBLE_CONTESTS"